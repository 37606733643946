import 'normalize.css';
import './styles/index.scss';
import { Elm } from './Main.elm';
import * as serviceWorker from './services/serviceWorker';
import GoogleAnalytics from './services/googleAnalytics';
import Survicate from './services/survicate';

const ga = new GoogleAnalytics(process.env.GTAG_MEASUREMENT_ID, process.env.GA_DEBUG_ENABLED === 'true');

const survicate = new Survicate();

window.addEventListener('DOMContentLoaded', (_event) => {

  const apiProtocol = process.env.BACKEND_PROTOCOL || window.location.protocol.replace(':','');
  const apiEndpoint = process.env.BACKEND_HOST || window.location.hostname;
  const apiPort = process.env.BACKEND_PORT ? `:${process.env.BACKEND_PORT}` : "";
  const combined_host = apiEndpoint.includes('://') ? apiEndpoint : `${apiProtocol}://${apiEndpoint}`;

  const globalMembershipFF = JSON.parse(process.env.FF_MEMBERSHIP) || false;
  
  const sessionEndpoint = `${combined_host}${apiPort}/session`
  const graphQLEndpoint = `${combined_host}${apiPort}/graphql`

  fetch(sessionEndpoint, { credentials: "include" }).then(resp => {
    if (resp.ok) {
      resp.json().then(contents => {

        ga.config(contents.authenticated_user);

        const darkMode = JSON.parse(localStorage.getItem("darkMode")) || false ;

        const userMembershipFF = JSON.parse(localStorage.getItem("userMembershipFF")) ;
        const membershipFF = userMembershipFF !== null ? userMembershipFF : globalMembershipFF;

        const locale = localStorage.getItem("locale") || "en";

        const storedViewState = sessionStorage.getItem("viewState");

        const elmApp = Elm.Main.init({
          flags: {
            apiEndpoint: graphQLEndpoint,
            csrfToken: contents.token,
            initialUser: contents.authenticated_user,
            useDarkMode: darkMode,
            membershipFF: membershipFF,
            useLocale: locale,
            initialViewState: storedViewState
          }
        });

        elmApp.ports.navigationEvent.subscribe((linkName) => {          
          ga.event(linkName);
        });

        elmApp.ports.externalLinkClick.subscribe(() => {          
          ga.pageView();
        });

        elmApp.ports.scrollToTop.subscribe(() => {
          window.scrollTo(0, 0);
        });

        elmApp.ports.userChangedTheme.subscribe((useDarkMode) => {
          localStorage.setItem("darkMode", useDarkMode);
          ga.themeSwitch(useDarkMode);
        });

        elmApp.ports.userChangedViewState.subscribe((viewStateJSON) => {
          sessionStorage.setItem("viewState", viewStateJSON);
        });

        elmApp.ports.userSearched.subscribe((query) => {
          ga.search(query);
        });

        elmApp.ports.userSignedIn.subscribe(function(authenticatedUser) {
          ga.login(authenticatedUser);
        });

        elmApp.ports.userSignedOut.subscribe(function() {
          ga.logout();
        });

        elmApp.ports.userSignedUp.subscribe(function(authenticatedUser) {
          ga.signup(authenticatedUser);
        });

        elmApp.ports.userSignUpStep.subscribe(function(params) {
          ga.signUpStep(params);
        });

        elmApp.ports.trackItemAction.subscribe(function(eventName) {
          ga.event(eventName);
        });

        elmApp.ports.trackUserAction.subscribe(function(eventName) {
          ga.event(eventName);
        });

        elmApp.ports.trackTopicAction.subscribe(function(eventName) {
          ga.event(eventName);
        });

        elmApp.ports.trackDiscussionAction.subscribe(function(eventName) {
          ga.event(eventName);
        });

        elmApp.ports.trackHashtagEvent.subscribe(function(eventName, tagName) {
          ga.hashtag(eventName, tagName);
        });

        elmApp.ports.trackAddItem.subscribe(function(itemType) {
          ga.itemEvent('add_item', itemType);
        });

        elmApp.ports.trackEditItem.subscribe(function(itemType) {
          ga.itemEvent('edit_item', itemType);
        });

        elmApp.ports.trackDeleteItem.subscribe(function() {
          ga.event('delete_item');
        });

        elmApp.ports.trackAddTopic.subscribe(function() {
          ga.event('add_topic');
        });

        elmApp.ports.trackEditTopic.subscribe(function() {
          ga.event('edit_topic');
        });

        elmApp.ports.trackAddDiscussion.subscribe(function() {
          ga.event('add_discussion');
        });

        elmApp.ports.trackEditDiscussion.subscribe(function() {
          ga.event('edit_discussion');
        });

        elmApp.ports.trackDeleteDiscussion.subscribe(function() {
          ga.event('delete_discussion');
        });

        elmApp.ports.trackAddDiscussionComment.subscribe(function() {
          ga.event('add_discussion_comment');
        });

        elmApp.ports.trackEditProfile.subscribe(function() {
          ga.event('edit_profile');
        });

        elmApp.ports.trackAddGroup.subscribe(function() {
          ga.event('add_group');
        });

        elmApp.ports.trackEditGroup.subscribe(function() {
          ga.event('edit_group');
        });

        elmApp.ports.trackJoinGroup.subscribe(function() {
          ga.event('join_group');
        });

        elmApp.ports.trackLeaveGroup.subscribe(function() {
          ga.event('leave_group');
        });

        elmApp.ports.trackTopicFilter.subscribe(function(filterBy) {
          ga.filterEvent('filter_topic', filterBy);
        });

        elmApp.ports.trackProfileTab.subscribe(function(profileTab) {
          ga.trackTab('view_profile_tab', profileTab);
        });

        elmApp.ports.trackNotificationClick.subscribe(function(action) {
          ga.notificationClick(action);
        });

        elmApp.ports.trackSendInvitationEvent.subscribe(function(eventName) {
          ga.event(eventName);
        });

        elmApp.ports.trackAcceptInvitationEvent.subscribe(function() {
          ga.event('accept_invitation');
        });

      });
    }
  });
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
